import React, { useState, useEffect } from 'react';
import { useAuthStore } from '@/lib/store';
import { cn, formatDate } from '@/lib/utils';
import { Users, CreditCard, BarChart, Settings, LogOut, ChevronRight, PanelLeftClose, PanelLeftOpen, Sparkles, Package } from 'lucide-react';
import { ProductsSection } from './dashboard/ProductsSection';
import { collection, getDocs, query, orderBy, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { UserDetails } from './dashboard/UserDetails';
import type { UserData } from './dashboard/types';
import { StatsGrid } from './dashboard/StatsGrid';

const SECTIONS = {
  DASHBOARD: 'dashboard',
  USERS: 'users',
  SUBSCRIPTIONS: 'subscriptions',
  PRODUCTS: 'products',
  SETTINGS: 'settings'
} as const;

const navigation = [
  { name: 'Dashboard', icon: BarChart, id: SECTIONS.DASHBOARD },
  { name: 'Users', icon: Users, id: SECTIONS.USERS },
  { name: 'Products', icon: Package, id: SECTIONS.PRODUCTS },
  { name: 'Subscriptions', icon: CreditCard, id: SECTIONS.SUBSCRIPTIONS },
  { name: 'Settings', icon: Settings, id: SECTIONS.SETTINGS },
];

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  message: string;
  confirmLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  isDangerous?: boolean;
}

function ConfirmationDialog({ 
  isOpen, 
  title, 
  message, 
  confirmLabel, 
  onConfirm, 
  onCancel,
  isDangerous 
}: ConfirmationDialogProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-charcoal-950/80 backdrop-blur-sm z-[100] flex items-center justify-center p-4">
      <div className={cn(
        "bg-charcoal-900 rounded-xl max-w-md w-full",
        "border border-charcoal-800",
        "animate-in zoom-in-95 duration-200"
      )}>
        <div className="p-6 space-y-4">
          <h3 className="text-lg font-medium text-white">{title}</h3>
          <p className="text-charcoal-300">{message}</p>
          
          <div className="flex items-center justify-end gap-3 pt-4">
            <button
              onClick={onCancel}
              className="px-4 py-2 text-sm text-charcoal-400 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className={cn(
                "px-4 py-2 rounded-lg text-sm font-medium",
                isDangerous ? (
                  "bg-red-500/10 text-red-400 border border-red-500/20 hover:bg-red-500/20"
                ) : (
                  "bg-amber-500/10 text-amber-400 border border-amber-500/20 hover:bg-amber-500/20"
                ),
                "transition-colors duration-200"
              )}
            >
              {confirmLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AdminDashboard() {
  const { user } = useAuthStore();
  const [activeSection, setActiveSection] = useState<keyof typeof SECTIONS>(SECTIONS.DASHBOARD);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [products, setProducts] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('active');
  const [confirmationDialog, setConfirmationDialog] = useState<{
    isOpen: boolean;
    title: string;
    message: string;
    confirmLabel: string;
    onConfirm: () => void;
    isDangerous?: boolean;
  } | null>(null);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeSubscriptions: 0,
    trialUsers: 0
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch products first
        const productsRef = collection(db, 'products');
        const productsSnapshot = await getDocs(productsRef);
        const productsMap: Record<string, string> = {};
        productsSnapshot.forEach(doc => {
          productsMap[doc.id] = doc.data().name;
        });
        setProducts(productsMap);

        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        
        const userData: UserData[] = [];
        let activeSubscriptions = 0;
        let trialUsers = 0;
        let latestTrialEnd = null;
        let currentPlan = null;

        snapshot.forEach((doc) => {
          const data = doc.data();
          
          // Count subscription stats
          if (data.subscription?.status === 'active') {
            activeSubscriptions++;
            currentPlan = productsMap[data.subscription?.plan] || data.subscription?.plan;
          }
          if (data.subscription?.status === 'trial') {
            trialUsers++;
            if (data.subscription?.trialEndsAt) {
              const trialEnd = data.subscription.trialEndsAt.toDate();
              if (!latestTrialEnd || trialEnd > latestTrialEnd) {
                latestTrialEnd = trialEnd;
              }
            }
          }
          
          userData.push({
            id: doc.id,
            email: data.email || '',
            displayName: data.displayName,
            createdAt: data.createdAt?.toDate() || new Date(),
            lastLoginAt: data.lastLoginAt?.toDate() || new Date(),
            deactivated: data.deactivated || false,
            deleted: data.deleted || false,
            subscription: {
              status: data.subscription?.status || 'none',
              plan: data.subscription?.plan
            }
          });
        });

        setUsers(userData);
        setStats({
          totalUsers: userData.length,
          activeSubscriptions,
          trialUsers,
          trialEndsAt: latestTrialEnd,
          subscriptionPlan: currentPlan
        });

      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Handle click outside sidebar
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.querySelector('.admin-sidebar');
      if (sidebar && !sidebar.contains(event.target as Node)) {
        setIsSidebarCollapsed(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const updateUserStatus = async (userId: string, updates: Partial<UserData>) => {
    try {
      const userRef = doc(db, 'users', userId);
      const timestamp = serverTimestamp();
      await updateDoc(userRef, {
        ...updates,
        updatedAt: timestamp
      });
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, ...updates } : user
      ));
      
      // Close user details
      if (updates.deactivated || updates.deleted) {
        setSelectedUser(null);
      }
    } catch (err) {
      console.error('Error updating user:', err);
      setError('Failed to update user');
    }
  };

  const handleUpdateProduct = async (userId: string, productId: string | null) => {
    try {
      const userRef = doc(db, 'users', userId);
      const timestamp = serverTimestamp();
      
      // Only update the plan, preserve existing subscription status
      const updateData = {
        'subscription.plan': productId,
        'updatedAt': timestamp
      };

      // Update Firestore
      await updateDoc(userRef, updateData);

      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? {
          ...user,
          subscription: {
            ...user.subscription,
            plan: productId
          }
        } : user
      ));

      // Close modal after successful update
      setTimeout(() => setSelectedUser(null), 500);
    } catch (err) {
      console.error('Error updating user product:', err);
      throw new Error('Failed to update user product');
    }
  };
  const handleDeactivateUser = async (userId: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;

    setConfirmationDialog({
      isOpen: true,
      title: 'Deactivate User',
      message: `Are you sure you want to deactivate user "${user.email}"? They will no longer be able to access their account.`,
      confirmLabel: 'Deactivate',
      onConfirm: async () => {
        await updateUserStatus(userId, { deactivated: true });
        setConfirmationDialog(null);
      },
      isDangerous: false
    });
  };

  const handleDeleteUser = async (userId: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;

    setConfirmationDialog({
      isOpen: true,
      title: 'Delete User',
      message: `Are you absolutely sure you want to delete user "${user.email}"? This action cannot be undone and will permanently delete all user data.`,
      confirmLabel: 'Delete Forever',
      onConfirm: async () => {
        await updateUserStatus(userId, { deleted: true });
        setConfirmationDialog(null);
      },
      isDangerous: true
    });
  };

  // Filter users based on search term and status
  const filteredUsers = users.filter(user => {
    const matchesSearch = user.email.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = statusFilter === 'all' || 
                         (statusFilter === 'active' && !user.deactivated && !user.deleted) ||
                         (statusFilter === 'deactivated' && user.deactivated) ||
                         (statusFilter === 'deleted' && user.deleted);
    return matchesSearch && matchesStatus;
  });

  return (
    <div className="min-h-screen bg-charcoal-950">
      {/* Sidebar */}
      <div className={cn(
        "admin-sidebar",
        "fixed inset-y-0 left-0 bg-charcoal-900 border-r border-charcoal-800",
        "transition-all duration-300 ease-in-out transform",
        isSidebarCollapsed ? "-translate-x-full" : "translate-x-0",
        "w-64"
      )}>
        <div className="flex flex-col h-full">
          <div className={cn(
            "flex items-center h-16 border-b border-charcoal-800",
            "justify-between px-6"
          )}>
            <span className="text-xl font-bold text-white">Phil Admin</span>
            <button
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
              className={cn(
                "p-1.5 rounded-lg",
                "text-charcoal-400 hover:text-white",
                "hover:bg-charcoal-800/50",
                "transition-colors duration-200"
              )}
            >
              {isSidebarCollapsed ? (
                <PanelLeftOpen className="w-5 h-5" />
              ) : (
                <PanelLeftClose className="w-5 h-5" />
              )}
            </button>
          </div>

          <nav className="flex-1 p-3 space-y-1">
            {navigation.map((item) => (
              <button
                key={item.name}
                onClick={() => {
                  setActiveSection(item.id);
                  setIsSidebarCollapsed(true);
                }}
                className={cn(
                  "flex items-center w-full rounded-lg",
                  isSidebarCollapsed ? "justify-center p-2" : "justify-between px-4 py-2.5",
                  "group hover:bg-charcoal-800/50",
                  "transition-all duration-200",
                  activeSection === item.id ? (
                    "bg-electric-blue/10 text-electric-blue"
                  ) : (
                    "text-charcoal-400 hover:text-white"
                  )
                )}
              >
                <div className={cn(
                  "flex items-center",
                  !isSidebarCollapsed && "gap-3"
                )}>
                  <item.icon className={cn(
                    "w-5 h-5",
                    activeSection === item.id ? "text-electric-blue" : "text-charcoal-400 group-hover:text-white"
                  )} />
                  {!isSidebarCollapsed && <span className="text-sm font-medium">{item.name}</span>}
                </div>
                {!isSidebarCollapsed && <ChevronRight className={cn(
                  "w-4 h-4 transition-transform",
                  activeSection === item.id ? "text-electric-blue rotate-90" : "text-charcoal-500"
                )} />}
              </button>
            ))}
          </nav>
          
          <div className={cn(
            "border-t border-charcoal-800",
            isSidebarCollapsed ? "p-2" : "p-3"
          )}>
            <button
              onClick={() => useAuthStore.getState().setUser(null)}
              className={cn(
                "flex items-center w-full rounded-lg",
                isSidebarCollapsed ? "justify-center p-2" : "gap-3 px-4 py-2.5",
                "text-charcoal-400 hover:text-white hover:bg-charcoal-800/50",
                "transition-colors duration-200"
              )}
            >
              <LogOut className="w-5 h-5" />
              {!isSidebarCollapsed && <span className="text-sm font-medium">Sign out</span>}
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className={cn(
        "min-h-screen transition-all duration-300 pb-8",
        "pl-[5rem]"
      )}>
        <header className="h-16 bg-charcoal-900 border-b border-charcoal-800 px-8 flex items-center justify-between sticky top-0 z-10">
          <div className="flex items-center gap-4">
            <button
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
              className={cn(
                "p-1.5 rounded-lg",
                "text-charcoal-400 hover:text-white",
                "hover:bg-charcoal-800/50",
                "transition-colors duration-200"
              )}
            >
              {isSidebarCollapsed ? (
                <PanelLeftOpen className="w-5 h-5" />
              ) : (
                <PanelLeftClose className="w-5 h-5" />
              )}
            </button>
            <h1 className="text-xl font-bold text-white">
              {navigation.find(item => item.id === activeSection)?.name}
            </h1>
          </div>
        </header>

        <main className="p-8 max-w-[1600px] mx-auto space-y-8">
          {/* Dashboard Section */}
          {activeSection === SECTIONS.DASHBOARD && (
            <>
              <StatsGrid stats={stats} />
              <div className={cn(
                "bg-charcoal-900/90 rounded-xl border border-charcoal-800 overflow-hidden",
                "hover:border-electric-blue/20 transition-colors duration-200"
              )}>
                <div className="px-6 py-4 border-b border-charcoal-800">
                  <h2 className="text-lg font-medium text-white">Recent Activity</h2>
                </div>
                <div className="p-6 text-charcoal-400">
                  Activity feed coming soon...
                </div>
              </div>
            </>
          )}

          {/* Users Section */}
          {activeSection === SECTIONS.USERS && (
            <div className={cn(
              "bg-charcoal-900/90 rounded-xl border border-charcoal-800 overflow-hidden",
              "hover:border-electric-blue/20 transition-colors duration-200"
            )}>
              <div className="px-6 py-4 border-b border-charcoal-800 flex items-center justify-between">
                <h2 className="text-lg font-medium text-white">Users</h2>
                <div className="flex items-center gap-4">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={cn(
                      "px-3 py-1.5 text-sm rounded-lg",
                      "bg-charcoal-800 border border-charcoal-700",
                      "text-white placeholder-charcoal-400",
                      "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                    )}
                  />
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className={cn(
                      "px-3 py-1.5 text-sm rounded-lg",
                      "bg-charcoal-800 border border-charcoal-700",
                      "text-white",
                      "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                    )}
                  >
                    <option value="all">All Users</option>
                    <option value="active">Active</option>
                    <option value="deactivated">Deactivated</option>
                    <option value="deleted">Deleted</option>
                  </select>
                </div>
              </div>
              
              {loading ? (
                <div className="p-8 text-center text-charcoal-400">Loading...</div>
              ) : error ? (
                <div className="p-8 text-center text-red-400">{error}</div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-charcoal-800 bg-charcoal-800/30">
                        <th className="px-6 py-3 text-left text-xs font-medium text-charcoal-400">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-charcoal-400">Account Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-charcoal-400">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-charcoal-400">Subscription</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-charcoal-400">Created</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-charcoal-400">Last Login</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user) => (
                        <tr 
                          key={user.id} 
                          className={cn(
                            "border-b border-charcoal-800",
                            "hover:bg-charcoal-800/30 transition-colors duration-200",
                            "cursor-pointer"
                          )}
                          onClick={() => setSelectedUser(user)}
                        >
                          <td className="px-6 py-4 text-sm text-white">{user.email}</td>
                          <td className="px-6 py-4">
                            <span className={cn(
                              "px-2 py-1 text-xs rounded-full",
                              user.deleted && "bg-red-400/10 text-red-400 border border-red-400/20",
                              user.deactivated && "bg-amber-400/10 text-amber-400 border border-amber-400/20",
                              !user.deleted && !user.deactivated && "bg-green-400/10 text-green-400 border border-green-400/20"
                            )}>
                              {user.deleted ? 'Deleted' : user.deactivated ? 'Deactivated' : 'Active'}
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <span className={cn(
                              "px-2 py-1 text-xs rounded-full whitespace-nowrap",
                              "inline-flex items-center gap-1.5",
                              user.subscription.status === 'active' && "bg-green-400/10 text-green-400 border border-green-400/20 hover:bg-green-400/20",
                              user.subscription.status === 'trial' && "bg-gradient-to-r from-electric-blue/10 to-purple-500/10 text-electric-blue border border-electric-blue/20 hover:border-electric-blue/40",
                              user.subscription.status === 'none' && "bg-charcoal-400/10 text-charcoal-400 border border-charcoal-400/20 hover:bg-charcoal-400/20"
                            )}>
                              {user.subscription.status === 'trial' ? (
                                <><Sparkles className="w-3 h-3" />Trial</>
                              ) : (
                                user.subscription.status
                              )}
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <span className={cn(
                              "px-2 py-1 text-xs rounded-full",
                              "bg-charcoal-800/50 border border-charcoal-700/50",
                              "text-charcoal-300"
                            )}>
                              {products[user.subscription.plan] || user.subscription.plan || 'No Plan'}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-sm text-charcoal-300">{formatDate(user.createdAt)}</td>
                          <td className="px-6 py-4 text-sm text-charcoal-300">{formatDate(user.lastLoginAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}

          {/* Products Section */}
          {activeSection === SECTIONS.PRODUCTS && <ProductsSection />}
          
          {/* Subscriptions Section */}
          {activeSection === SECTIONS.SUBSCRIPTIONS && (
            <div className={cn(
              "bg-charcoal-900/90 rounded-xl border border-charcoal-800 overflow-hidden",
              "hover:border-electric-blue/20 transition-colors duration-200"
            )}>
              <div className="px-6 py-4 border-b border-charcoal-800">
                <h2 className="text-lg font-medium text-white">Subscriptions</h2>
              </div>
              <div className="p-6 text-charcoal-400">
                Subscription management coming soon...
              </div>
            </div>
          )}
          
          {/* Settings Section */}
          {activeSection === SECTIONS.SETTINGS && (
            <div className={cn(
              "bg-charcoal-900/90 rounded-xl border border-charcoal-800 overflow-hidden",
              "hover:border-electric-blue/20 transition-colors duration-200"
            )}>
              <div className="px-6 py-4 border-b border-charcoal-800">
                <h2 className="text-lg font-medium text-white">Settings</h2>
              </div>
              <div className="p-6 text-charcoal-400">
                Admin settings coming soon...
              </div>
            </div>
          )}
        </main>
      </div>
      
      {/* User Details Modal */}
      <UserDetails
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
        onDeactivate={handleDeactivateUser}
        onDelete={handleDeleteUser}
        onUpdateProduct={handleUpdateProduct}
      />
      
      {/* Confirmation Dialog */}
      {confirmationDialog && (
        <ConfirmationDialog
          isOpen={confirmationDialog.isOpen}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          confirmLabel={confirmationDialog.confirmLabel}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={() => setConfirmationDialog(null)}
          isDangerous={confirmationDialog.isDangerous}
        />
      )}
    </div>
  );
}