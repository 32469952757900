import React, { useState } from 'react';
import { signInWithEmailAndPassword } from '@/lib/auth';
import { cn } from '@/lib/utils';
import { Lock, AlertCircle, Loader2 } from 'lucide-react';

export function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(email, password);
    } catch (err: any) {
      setError('Invalid admin credentials');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <div className="flex items-center justify-center mb-4">
            <div className={cn(
              "p-4 rounded-full",
              "bg-gradient-to-r from-electric-blue/10 to-blue-500/10",
              "border border-electric-blue/20"
            )}>
              <Lock className="w-8 h-8 text-electric-blue" />
            </div>
          </div>
          <h2 className="text-2xl font-bold text-white">Admin Access</h2>
          <p className="mt-2 text-charcoal-400">Sign in to access the admin dashboard</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Admin Email"
              className={cn(
                "w-full px-4 py-3 rounded-lg",
                "bg-charcoal-800 border border-charcoal-700",
                "text-white placeholder-charcoal-400",
                "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
              )}
            />
          </div>

          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className={cn(
                "w-full px-4 py-3 rounded-lg",
                "bg-charcoal-800 border border-charcoal-700",
                "text-white placeholder-charcoal-400",
                "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
              )}
            />
          </div>

          {error && (
            <div className="flex items-center gap-2 text-red-400 text-sm">
              <AlertCircle className="w-4 h-4" />
              <p>{error}</p>
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className={cn(
              "w-full py-3 rounded-lg font-medium",
              "bg-electric-blue text-charcoal-900",
              "hover:bg-electric-blue/90",
              "transition-colors duration-200",
              "disabled:opacity-50 disabled:cursor-not-allowed",
              "flex items-center justify-center gap-2"
            )}
          >
            {isLoading ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                Signing in...
              </>
            ) : (
              'Sign in'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}