import { useAuthStore } from '../src/lib/store';
import { AdminLogin } from './components/AdminLogin';
import { AdminDashboard } from './components/AdminDashboard';
import { Loader2 } from 'lucide-react';
import { useState, useEffect } from 'react';

export default function App() {
  const { user, initialized } = useAuthStore();
  const [isCheckingClaims, setIsCheckingClaims] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  // Check superAdmin claims on auth state change
  useEffect(() => {
    setIsCheckingClaims(true);
    setHasAccess(false);

    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        const isSuperAdmin = !!idTokenResult.claims?.superAdmin;
        setHasAccess(isSuperAdmin);
        
        if (!isSuperAdmin) {
          alert("Access denied. Super admins only.");
          useAuthStore.getState().setUser(null);
        }
      }).finally(() => {
        setIsCheckingClaims(false);
      });
    } else {
      setIsCheckingClaims(false);
    }
  }, [user]);

  if (!initialized || isCheckingClaims) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-charcoal-950">
        <div className="flex items-center gap-3">
          <Loader2 className="w-6 h-6 text-electric-blue animate-spin" />
          <span className="text-electric-blue">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-charcoal-950">
      {user && hasAccess ? <AdminDashboard /> : <AdminLogin />}
    </div>
  );
}