import React from 'react';
import { Users, CreditCard, BarChart, Calendar, Crown } from 'lucide-react';
import type { StatsGridProps } from './types';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';

export function StatsGrid({ stats }: StatsGridProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
      <div className={cn(
        "p-6 rounded-xl",
        "bg-charcoal-900/90 border border-charcoal-800",
        "hover:border-electric-blue/20 transition-colors duration-200"
      )}>
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium text-charcoal-400">Total Users</h3>
            <p className="mt-2 text-3xl font-bold text-white">{stats.totalUsers}</p>
          </div>
          <Users className="w-5 h-5 text-electric-blue" />
        </div>
      </div>

      <div className={cn(
        "p-6 rounded-xl",
        "bg-charcoal-900/90 border border-charcoal-800",
        "hover:border-electric-blue/20 transition-colors duration-200"
      )}>
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium text-charcoal-400">Active Subscriptions</h3>
            <p className="mt-2 text-3xl font-bold text-white">{stats.activeSubscriptions}</p>
          </div>
          <CreditCard className="w-5 h-5 text-electric-blue" />
        </div>
      </div>


      <div className={cn(
        "p-6 rounded-xl",
        "bg-charcoal-900/90 border border-charcoal-800",
        "hover:border-electric-blue/20 transition-colors duration-200"
      )}>
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium text-charcoal-400">Trial Users</h3>
            <p className="mt-2 text-3xl font-bold text-white">{stats.trialUsers}</p>
          </div>
          <BarChart className="w-5 h-5 text-electric-blue" />
        </div>
      </div>
      
      <div className={cn(
        "p-6 rounded-xl",
        "bg-charcoal-900/90 border border-charcoal-800",
        "hover:border-electric-blue/20 transition-colors duration-200"
      )}>
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium text-charcoal-400">Trial End Date</h3>
            <p className="mt-2 text-xl font-bold text-white">
              {stats.trialEndsAt instanceof Date ? format(stats.trialEndsAt, 'MMM d, yyyy') : 'N/A'}
            </p>
          </div>
          <Calendar className="w-5 h-5 text-electric-blue" />
        </div>
      </div>
      
      <div className={cn(
        "p-6 rounded-xl",
        "bg-charcoal-900/90 border border-charcoal-800",
        "hover:border-electric-blue/20 transition-colors duration-200"
      )}>
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium text-charcoal-400">Subscription Plan</h3>
            <p className="mt-2 text-xl font-bold text-white">
              {stats.subscriptionPlan || 'No Active Plan'}
            </p>
          </div>
          <Crown className="w-5 h-5 text-electric-blue" />
        </div>
      </div>
    </div>
  );
}