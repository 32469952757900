import React from 'react';
import { cn } from '@/lib/utils';
import { X, UserX, Trash2, Package } from 'lucide-react';
import type { UserData } from './types';
import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import type { Product } from './types';

interface UserDetailsProps {
  user: UserData | null;
  onClose: () => void;
  onDeactivate: (userId: string) => void;
  onDelete: (userId: string) => void;
  onUpdateProduct: (userId: string, productId: string | null) => void;
}

export function UserDetails({ user, onClose, onDeactivate, onDelete, onUpdateProduct }: UserDetailsProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [updateError, setUpdateError] = useState<string | null>(null);

  // Only fetch products when modal is open
  useEffect(() => {
    if (user) {
      const fetchProducts = async () => {
        try {
          const productsRef = collection(db, 'products');
          const snapshot = await getDocs(productsRef);
          const productsData = snapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data()
            }))
            .filter(product => product.active) as Product[];
          setProducts(productsData);
          setUpdateError(null);
        } catch (err) {
          console.error('Error fetching products:', err);
          setUpdateError('Failed to load products');
        } finally {
          setLoading(false);
        }
      };
      
      fetchProducts();
      
      // Reset states when modal closes
      return () => {
        setProducts([]);
        setLoading(true);
        setUpdateError(null);
      }
    }
  }, [user]);

  const handleProductChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (!user?.id) return;
      const productId = e.target.value || null;

      try {
        setUpdateError(null);
        await onUpdateProduct(user.id, productId);
      } catch (err) {
        console.error('Error updating product:', err);
        setUpdateError('Failed to update product');
      }
    },
    [user?.id, onUpdateProduct]
  );

  if (!user) return null;

  return (
    <div className="fixed inset-0 bg-charcoal-950/80 backdrop-blur-sm z-[100] flex items-center justify-center p-4">
      <div 
        className={cn(
          "bg-charcoal-900 rounded-xl max-w-2xl w-full",
          "border border-charcoal-800",
          "animate-in slide-in-from-bottom-4 duration-300"
        )}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-charcoal-800">
          <h3 className="text-lg font-medium text-white">User Details</h3>
          <button
            onClick={onClose}
            className="p-2 text-charcoal-400 hover:text-white rounded-lg hover:bg-charcoal-800"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          {/* Basic Info */}
          <div className="space-y-4">
            <div>
              <label className="text-sm text-charcoal-400">Email</label>
              <p className="text-white">{user.email}</p>
            </div>
            <div>
              <label className="text-sm text-charcoal-400">Display Name</label>
              <p className="text-white">{user.displayName || '-'}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm text-charcoal-400">Created</label>
                <p className="text-white">{user.createdAt.toLocaleDateString()}</p>
              </div>
              <div>
                <label className="text-sm text-charcoal-400">Last Login</label>
                <p className="text-white">{user.lastLoginAt.toLocaleDateString()}</p>
              </div>
            </div>
          </div>

          {/* Subscription Info */}
          <div className="space-y-4">
            <div>
              <label className="text-sm text-charcoal-400">Subscription Status</label>
              <div className="mt-1">
                <div className="flex items-center gap-2">
                  <span className={cn(
                    "px-2 py-1 text-xs rounded-full",
                    user.subscription.status === 'active' && "bg-green-400/10 text-green-400 border border-green-400/20",
                    user.subscription.status === 'trial' && "bg-blue-400/10 text-blue-400 border border-blue-400/20",
                    user.subscription.status === 'none' && "bg-charcoal-400/10 text-charcoal-400 border border-charcoal-400/20"
                  )}>
                    {user.subscription.status}
                  </span>
                  {user.subscription.plan && (
                    <span className="text-xs text-charcoal-400">
                      (Plan changes don't affect trial status)
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div>
              <label className="text-sm text-charcoal-400">Product Package</label>
              <div className="mt-1">
                {loading ? (
                  <div className="text-sm text-charcoal-400">Loading products...</div>
                ) : (
                  <select
                    value={user?.subscription?.plan || ''}
                    onChange={handleProductChange}
                    className={cn(
                      "w-full px-3 py-2 rounded-lg",
                      "bg-charcoal-800 border border-charcoal-700",
                      "text-white",
                      "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                    )}
                  >
                    <option value="">No Package</option>
                    {products.map(product => (
                      <option key={product.id} value={product.id}>
                        {product.name} (${product.price})
                      </option>
                    ))}
                  </select>
                )}
                {updateError && (
                  <p className="mt-2 text-sm text-red-400">{updateError}</p>
                )}
              </div>
            </div>
          </div>

          {/* Actions */}
          <div className="flex items-center justify-end gap-3 pt-4 border-t border-charcoal-800">
            <button
              onClick={() => user?.id && onDeactivate(user.id)}
              className={cn(
                "flex items-center gap-2 px-4 py-2 rounded-lg",
                "bg-amber-500/10 text-amber-400",
                "border border-amber-500/20",
                "hover:bg-amber-500/20",
                "transition-colors duration-200"
              )}
            >
              <UserX className="w-4 h-4" />
              Deactivate
            </button>
            <button
              onClick={() => user?.id && onDelete(user.id)}
              className={cn(
                "flex items-center gap-2 px-4 py-2 rounded-lg",
                "bg-red-500/10 text-red-400",
                "border border-red-500/20",
                "hover:bg-red-500/20",
                "transition-colors duration-200"
              )}
            >
              <Trash2 className="w-4 h-4" />
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}