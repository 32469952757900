// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { cn } from '../../../src/lib/utils';
import { Plus, Pencil, Trash2, X, Check } from 'lucide-react';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import type { Product } from './types';

interface ProductFormData {
  name: string;
  description: string;
  price: number;
  features: {
    name: string;
    value?: number | boolean;
    type: 'limit' | 'access' | 'feature';
  }[];
  active: boolean;
}

const initialFormData: ProductFormData = {
  name: '',
  description: '',
  price: 0,
  features: [],
  active: true
};

const FEATURE_TYPES = [
  { id: 'limit', label: 'Limit', icon: 'Hash' },
  { id: 'access', label: 'Access', icon: 'Lock' },
  { id: 'feature', label: 'Feature', icon: 'Star' }
];

const DEFAULT_FEATURES = [
  { name: 'contentMaps', type: 'access', value: false },
  { name: 'learningPath', type: 'access', value: false },
  { name: 'activeChats', type: 'limit', value: 1 },
  { name: 'pathGenerations', type: 'limit', value: 10 },
  { name: 'customBranding', type: 'feature', value: false },
  { name: 'prioritySupport', type: 'feature', value: false }
];

export function ProductsSection() {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [formData, setFormData] = useState<ProductFormData>(initialFormData);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const productsRef = collection(db, 'products');
      const snapshot = await getDocs(productsRef);
      const productsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Product[];
      setProducts(productsData);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const timestamp = serverTimestamp();
      const productData = {
        ...formData,
        features: formData.features.filter(f => f.name.trim()),
        updatedAt: timestamp,
        createdAt: editingProduct ? editingProduct.createdAt : timestamp
      };

      if (editingProduct) {
        const { createdAt, ...updateData } = productData;
        await updateDoc(doc(db, 'products', editingProduct.id), {
          ...updateData,
          updatedAt: timestamp
        });
      } else {
        await addDoc(collection(db, 'products'), productData);
      }

      await fetchProducts();
      handleCancel();
    } catch (err) {
      console.error('Error saving product:', err);
      setError('Failed to save product');
    }
  };

  const handleDelete = async (productId: string) => {
    try {
      await deleteDoc(doc(db, 'products', productId));
      await fetchProducts();
      setShowDeleteConfirm(null);
    } catch (err) {
      console.error('Error deleting product:', err);
      setError('Failed to delete product');
    }
  };

  const handleEdit = (product: Product) => {
    setEditingProduct(product);
    setFormData({
      name: product.name,
      description: product.description,
      price: product.price,
      features: product.features?.map(f => ({ ...f })) || [],
      active: product.active
    });
    setIsEditing(true);
  };

  const handleAddDefaultFeatures = () => {
    setFormData(prev => ({
      ...prev,
      features: DEFAULT_FEATURES.map(f => ({ ...f }))
    }));
  };
  const handleCancel = () => {
    setIsEditing(false);
    setEditingProduct(null);
    setFormData(initialFormData);
  };

  const addFeature = () => {
    const newFeature = {
      name: '',
      type: 'feature' as const,
      value: false
    };
    setFormData(prev => ({ ...prev, features: [...prev.features, newFeature] }));
  };

  const updateFeature = (index: number, updates: Partial<ProductFormData['features'][0]>) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features.map((f, i) => 
        i === index ? { ...f, ...updates } : f
      )
    }));
  };

  const removeFeature = (index: number) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="space-y-6">
      {/* Products List */}
      <div className={cn(
        "bg-charcoal-900/90 rounded-xl border border-charcoal-800",
        "hover:border-electric-blue/20 transition-colors duration-200"
      )}>
        <div className="px-6 py-4 border-b border-charcoal-800 flex items-center justify-between">
          <h2 className="text-lg font-medium text-white">Products</h2>
          {!isEditing && (
            <button
              onClick={() => setIsEditing(true)}
              className={cn(
                "flex items-center gap-2 px-4 py-2 rounded-lg",
                "bg-electric-blue/10 text-electric-blue",
                "border border-electric-blue/20",
                "hover:bg-electric-blue/20",
                "transition-colors duration-200"
              )}
            >
              <Plus className="w-4 h-4" />
              Add Product
            </button>
          )}
        </div>

        {loading ? (
          <div className="p-8 text-center text-charcoal-400">Loading...</div>
        ) : error ? (
          <div className="p-8 text-center text-red-400">{error}</div>
        ) : isEditing ? (
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-charcoal-300 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    className={cn(
                      "w-full px-4 py-2 rounded-lg",
                      "bg-charcoal-800 border border-charcoal-700",
                      "text-white placeholder-charcoal-400",
                      "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                    )}
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-charcoal-300 mb-1">
                    Description
                  </label>
                  <textarea
                    value={formData.description}
                    onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
                    className={cn(
                      "w-full px-4 py-2 rounded-lg",
                      "bg-charcoal-800 border border-charcoal-700",
                      "text-white placeholder-charcoal-400",
                      "focus:outline-none focus:ring-2 focus:ring-electric-blue/50",
                      "resize-none h-24"
                    )}
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-charcoal-300 mb-1">
                    Price
                  </label>
                  <input
                    type="number"
                    value={formData.price}
                    onChange={e => setFormData(prev => ({ ...prev, price: parseFloat(e.target.value) }))}
                    className={cn(
                      "w-full px-4 py-2 rounded-lg",
                      "bg-charcoal-800 border border-charcoal-700",
                      "text-white placeholder-charcoal-400",
                      "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                    )}
                    required
                    min="0"
                    step="0.01"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-charcoal-300 mb-1">
                    Features
                  </label>
                  <div className="space-y-2">
                    {formData.features.map((feature, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <select
                          value={feature.type}
                          onChange={e => updateFeature(index, { type: e.target.value as any })}
                          className={cn(
                            "w-32 px-4 py-2 rounded-lg",
                            "bg-charcoal-800 border border-charcoal-700",
                            "text-white placeholder-charcoal-400",
                            "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                          )}
                        >
                          {FEATURE_TYPES.map(type => (
                            <option key={type.id} value={type.id}>{type.label}</option>
                          ))}
                        </select>

                        <input
                          type="text"
                          value={feature.name}
                          onChange={e => updateFeature(index, { name: e.target.value })}
                          className={cn(
                            "flex-1 px-4 py-2 rounded-lg",
                            "bg-charcoal-800 border border-charcoal-700",
                            "text-white placeholder-charcoal-400",
                            "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                          )}
                          placeholder="Feature name"
                        />

                        {feature.type === 'limit' ? (
                          <input
                            type="number"
                            value={feature.value as number || 0}
                            onChange={e => updateFeature(index, { value: parseInt(e.target.value) })}
                            className={cn(
                              "w-24 px-4 py-2 rounded-lg",
                              "bg-charcoal-800 border border-charcoal-700",
                              "text-white placeholder-charcoal-400",
                              "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                            )}
                            min="0"
                          />
                        ) : (
                          <button
                            type="button"
                            onClick={() => updateFeature(index, { value: !feature.value })}
                            className={cn(
                              "px-4 py-2 rounded-lg text-sm",
                              feature.value
                                ? "bg-green-400/10 text-green-400 border border-green-400/20"
                                : "bg-charcoal-800 text-charcoal-400 border border-charcoal-700",
                              "transition-colors duration-200"
                            )}
                          >
                            {feature.value ? 'Enabled' : 'Disabled'}
                          </button>
                        )}

                        <button
                          type="button"
                          onClick={() => removeFeature(index)}
                          className="p-2 text-charcoal-400 hover:text-red-400"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={formData.features.length === 0 ? handleAddDefaultFeatures : addFeature}
                      className={cn(
                        "flex items-center gap-2 px-4 py-2 rounded-lg w-full",
                        "bg-charcoal-800/50 border border-dashed border-charcoal-700",
                        "text-charcoal-400 hover:text-white",
                        "hover:bg-charcoal-800",
                        "transition-colors duration-200",
                        "disabled:opacity-50 disabled:cursor-not-allowed"
                      )}
                    >
                      <Plus className="w-4 h-4" />
                      {formData.features.length === 0 ? 'Add Default Features' : 'Add Feature'}
                    </button>
                    {formData.features.length === 0 && (
                      <p className="text-xs text-charcoal-400 mt-2">
                        Click to add the default set of features and limits
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={formData.active}
                    onChange={e => setFormData(prev => ({ ...prev, active: e.target.checked }))}
                    className="rounded border-charcoal-700 bg-charcoal-800 text-electric-blue focus:ring-electric-blue"
                  />
                  <label className="text-sm font-medium text-charcoal-300">
                    Active
                  </label>
                </div>
              </div>

              <div className="flex items-center justify-end gap-3 pt-4 border-t border-charcoal-800">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 text-sm text-charcoal-400 hover:text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    "flex items-center gap-2 px-4 py-2 rounded-lg",
                    "bg-electric-blue/10 text-electric-blue",
                    "border border-electric-blue/20",
                    "hover:bg-electric-blue/20",
                    "transition-colors duration-200"
                  )}
                >
                  <Check className="w-4 h-4" />
                  {editingProduct ? 'Update' : 'Create'} Product
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="divide-y divide-charcoal-800">
            {products.map(product => (
              <div
                key={product.id}
                className={cn(
                  "p-6 hover:bg-charcoal-800/30",
                  "transition-colors duration-200"
                )}
              >
                <div className="flex items-start justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center gap-3">
                      <h3 className="text-lg font-medium text-white">
                        {product.name}
                      </h3>
                      <span className={cn(
                        "px-2 py-0.5 text-xs rounded-full",
                        product.active
                          ? "bg-green-400/10 text-green-400 border border-green-400/20"
                          : "bg-amber-400/10 text-amber-400 border border-amber-400/20"
                      )}>
                        {product.active ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                    <p className="text-charcoal-300">{product.description}</p>
                    <p className="text-lg font-medium text-electric-blue">
                      ${product.price.toFixed(2)}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleEdit(product)}
                      className={cn(
                        "p-2 rounded-lg",
                        "text-charcoal-400 hover:text-white",
                        "hover:bg-charcoal-800",
                        "transition-colors duration-200"
                      )}
                    >
                      <Pencil className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => setShowDeleteConfirm(product.id)}
                      className={cn(
                        "p-2 rounded-lg",
                        "text-charcoal-400 hover:text-red-400",
                        "hover:bg-red-500/10",
                        "transition-colors duration-200"
                      )}
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>

                {/* Features */}
                {product.features.length > 0 && (
                  <div className="mt-4 space-y-2">
                    <h4 className="text-sm font-medium text-charcoal-400">Features</h4>
                    <ul className="space-y-2">
                      {product.features.map((feature, index) => (
                        <li key={index} className="flex items-center justify-between text-sm">
                          <div className="flex items-center gap-2">
                            <span className={cn(
                              "w-1.5 h-1.5 rounded-full",
                              feature.type === 'limit' && "bg-amber-400/50",
                              feature.type === 'access' && "bg-electric-blue/50",
                              feature.type === 'feature' && "bg-purple-400/50"
                            )} />
                            <span className="text-charcoal-300">{feature.name}</span>
                          </div>
                          <span className={cn(
                            "px-2 py-0.5 rounded text-xs",
                            feature.type === 'limit'
                              ? "bg-amber-400/10 text-amber-400 border border-amber-400/20"
                              : feature.value
                                ? "bg-green-400/10 text-green-400 border border-green-400/20"
                                : "bg-red-400/10 text-red-400 border border-red-400/20"
                          )}>
                            {feature.type === 'limit' 
                              ? feature.value
                              : feature.value ? 'Enabled' : 'Disabled'}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-charcoal-950/80 backdrop-blur-sm z-[100] flex items-center justify-center p-4">
          <div className={cn(
            "bg-charcoal-900 rounded-xl max-w-md w-full",
            "border border-charcoal-800",
            "animate-in zoom-in-95 duration-200"
          )}>
            <div className="p-6 space-y-4">
              <h3 className="text-lg font-medium text-white">Delete Product</h3>
              <p className="text-charcoal-300">
                Are you sure you want to delete this product? This action cannot be undone.
              </p>
              
              <div className="flex items-center justify-end gap-3 pt-4">
                <button
                  onClick={() => setShowDeleteConfirm(null)}
                  className="px-4 py-2 text-sm text-charcoal-400 hover:text-white"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleDelete(showDeleteConfirm)}
                  className={cn(
                    "px-4 py-2 rounded-lg text-sm font-medium",
                    "bg-red-500/10 text-red-400",
                    "border border-red-500/20",
                    "hover:bg-red-500/20",
                    "transition-colors duration-200"
                  )}
                >
                  Delete Forever
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}